import { Center, Flex, HStack, Text, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { MdKeyboardCommandKey, MdOutlineAutoAwesome } from "react-icons/md"

export default function HomeEmptyView() {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const phoneWidth = 480

    useEffect(() => {
        window.addEventListener("resize", screenWidthUpdated);
	})

    const screenWidthUpdated = () => {
        setScreenWidth(window.innerWidth)
    }

    const Prompt = (text: string) => {
        return <Text 
                    width={300}
                    textAlign={'center'} 
                    fontSize={"rem"} 
                    noOfLines={2}
                    bgColor='secondaryGray.100' 
                    whiteSpace={'pre-line'}
                    padding={"10px"} 
                    borderRadius={5}>
                        "{text}"
                </Text>
    }

    return (
		<Flex direction="column" position="relative" mt={150}>
            <Center>
                <HStack spacing={[50, 50, 75]}>
                    <VStack spacing={10}>
                        <VStack>
                            <VStack>
                                <MdKeyboardCommandKey size={50} />
                                <Text as='b' fontSize={'lg'}>Examples</Text>
                            </VStack>
                        </VStack>
                        { screenWidth > phoneWidth && 
                            <VStack spacing={5}>
                                { Prompt("What are the current population trends and projected growth rates?") }
                                { Prompt("Can you provide a detailed age distribution of the population?") }
                                { Prompt("What are the median household income & employment rates?") }
                            </VStack>
                        }

                        { screenWidth <= phoneWidth && 
                            <VStack spacing={5}>
                                { Prompt("What are the current population trends \nand projected growth rates?") }
                                { Prompt("Can you provide a detailed age distribution of the population?") }
                            </VStack>
                        }
                        
                    </VStack>
                    
                    <VStack spacing={10} display={{ base: 'none', md: 'block'}}>
                        <VStack>
                            <VStack>
                                <MdOutlineAutoAwesome size={50} />
                                <Text as='b' fontSize={'lg'}>Capabilities</Text>
                            </VStack>
                        </VStack>
                        <VStack spacing={5}>
                            { Prompt("Answer most questions \nabout U.S. cities") }
                            { Prompt("Allow you to mark a \nresponse as incorrect") }
                            { Prompt("Trained to decline questions not \nrelated to Consciously's dataset") }
                        </VStack>
                    </VStack>
                </HStack>
            </Center>
            <Center mt="20">
                <Text fontSize="2xl" fontWeight={"bold"} textAlign='center'>
                    Use the "New chat" button to start a conversation!
                </Text>
            </Center>
		</Flex>
	)
}