/*eslint-disable*/

import { Flex, Text, useColorModeValue } from '@chakra-ui/react';

export default function Footer() {
	const textColor = useColorModeValue('gray.400', 'white');
	return (
		<Flex
			zIndex='3'
			px={{ base: '30px', md: '50px' }}
			pb='30px'
		>
			<Text
			 	m='auto'
				color={textColor}
				mb={{ base: '20px', xl: '0px' }}
				textAlign="center"
			>
				&copy; {new Date().getFullYear()}
				{' '}
				<Text as='span' fontWeight='500' fontSize={'sm'}>
					Consciously. All Rights Reserved. Handcrafted with care in Seattle.
				</Text>
			</Text>
		</Flex>
	);
}
