// Chakra imports
import { Flex, Image } from '@chakra-ui/react';

// Custom components
import { HSeparator } from 'components/separator/Separator';
import logo from '../../../assets/img/logo/full-logo-lg.png'

export function SidebarBrand() {
	return (
		<Flex alignItems='center' flexDirection='column'>
			<Image src={logo} width='200px' height='216px' />
			<HSeparator mb='30px' mt='30px' />
		</Flex>
	);
}

export default SidebarBrand;
